@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,700");
.app .finish-resume .group-actions .btn-action, .app .finish-resume .group-actions .btn-action.next, .app .finish-resume .group-budget .btn-action {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.1); }

.app .finish-resume .group-actions .btn-action:hover, .app .finish-resume .group-actions .btn-action:focus, .app .finish-resume .group-budget .btn-action:hover, .app .finish-resume .group-budget .btn-action:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25), 0 3px 6px rgba(0, 0, 0, 0.35); }

.app .finish-resume .group-actions .btn-action:hover, .app .finish-resume .group-actions .btn-action:focus, .app .finish-resume .group-budget .btn-action:hover, .app .finish-resume .group-budget .btn-action:focus {
  filter: grayscale(0.2); }

.app {
  display: flex;
  width: 100vw; }
  .app .finish-resume {
    width: 70%;
    text-align: center; }
    .app .finish-resume .text-intro {
      font-size: 1.2em; }
    .app .finish-resume .form {
      margin-top: 32px;
      text-align: left; }
      .app .finish-resume .form .ipt-group {
        margin-bottom: 16px; }
        .app .finish-resume .form .ipt-group label {
          display: block;
          margin-bottom: 4px;
          color: #757575; }
        .app .finish-resume .form .ipt-group .ipt {
          width: 100%;
          height: 44px;
          padding: 0 10px;
          font-size: 1.1em;
          color: #212121;
          border: 1px silid #bdbdbd; }
        .app .finish-resume .form .ipt-group .textarea {
          height: 120px;
          padding: 5px 10px;
          border-color: #bdbdbd;
          resize: none; }
      .app .finish-resume .form .check-options {
        display: flex;
        justify-content: space-between; }
        @media only screen and (max-width: 560px) {
          .app .finish-resume .form .check-options {
            display: block; } }
      .app .finish-resume .form .check-group {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin: 20px 0 16px; }
        .app .finish-resume .form .check-group .check {
          margin-right: 10px; }
    .app .finish-resume .result-wrapper {
      text-align: left;
      padding: 32px; }
      .app .finish-resume .result-wrapper p {
        font-size: 16px; }
      .app .finish-resume .result-wrapper .total-resume {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 32px; }
        .app .finish-resume .result-wrapper .total-resume .box-total {
          width: 48%;
          padding: 4px 0 12px;
          margin-top: 8px;
          text-align: center;
          color: #fff;
          background-color: white;
          border-radius: 3px; }
          .app .finish-resume .result-wrapper .total-resume .box-total .sum-price {
            font-size: 2.4em;
            color: #1579aa; }
    .app .finish-resume .group-actions {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(6, calc(16.6% - 13px));
      grid-template-rows: repeat(2, auto);
      margin-top: 32px; }
      .app .finish-resume .group-actions .btn-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        border-radius: 3px;
        cursor: pointer;
        transition: all .3s ease-in-out; }
        .app .finish-resume .group-actions .btn-action.next {
          grid-column: 2 / 6;
          grid-row: 1 / 2;
          background-color: #4caf50; }
          .app .finish-resume .group-actions .btn-action.next img {
            width: 64px; }
          .app .finish-resume .group-actions .btn-action.next span {
            font-size: 1.2em; }
        .app .finish-resume .group-actions .btn-action.cancel {
          grid-column: 2 / 4;
          background-color: #f44336; }
        .app .finish-resume .group-actions .btn-action.refresh {
          grid-column: 4 / 6;
          background-color: #fbc02d; }
        .app .finish-resume .group-actions .btn-action img {
          width: 24px; }
        .app .finish-resume .group-actions .btn-action span {
          margin-left: 16px;
          font-size: 1em;
          color: #fff; }
        @media only screen and (max-width: 991px) {
          .app .finish-resume .group-actions .btn-action {
            flex-direction: column; }
            .app .finish-resume .group-actions .btn-action.next {
              grid-column: 1 / 7; }
              .app .finish-resume .group-actions .btn-action.next img {
                margin-bottom: 16px; }
            .app .finish-resume .group-actions .btn-action.cancel {
              grid-column: 1 / 4; }
            .app .finish-resume .group-actions .btn-action.refresh {
              grid-column: 4 / 7;
              background-color: #FBC02D; }
            .app .finish-resume .group-actions .btn-action img {
              margin-bottom: 8px; }
            .app .finish-resume .group-actions .btn-action span {
              margin-left: 0; } }
    .app .finish-resume .group-budget {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: repeat(6, calc(16.6% - 13px));
      margin: 32px 0; }
      .app .finish-resume .group-budget .btn-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        border-radius: 3px;
        cursor: pointer;
        transition: all .3s ease-in-out; }
        .app .finish-resume .group-budget .btn-action.next {
          grid-column: 1 / 3;
          background-color: #4caf50; }
        .app .finish-resume .group-budget .btn-action.refresh {
          grid-column: 3 / 5;
          background-color: #fbc02d; }
        .app .finish-resume .group-budget .btn-action.cancel {
          grid-column: 5 / 7;
          background-color: #f44336; }
        .app .finish-resume .group-budget .btn-action img {
          width: 24px; }
        .app .finish-resume .group-budget .btn-action span {
          margin-left: 16px;
          font-size: 1em;
          color: #fff; }
        @media only screen and (max-width: 991px) {
          .app .finish-resume .group-budget .btn-action {
            flex-direction: column; }
            .app .finish-resume .group-budget .btn-action.next {
              grid-column: 1 / 7; }
              .app .finish-resume .group-budget .btn-action.next img {
                margin-bottom: 16px; }
            .app .finish-resume .group-budget .btn-action.cancel {
              grid-column: 1 / 4; }
            .app .finish-resume .group-budget .btn-action.refresh {
              grid-column: 4 / 7;
              background-color: #FBC02D; }
            .app .finish-resume .group-budget .btn-action img {
              margin-bottom: 8px; }
            .app .finish-resume .group-budget .btn-action span {
              margin-left: 0; } }

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh; }
  .home .logo {
    width: 200px;
    margin-bottom: 16px; }
  .home .logo-masters {
    position: absolute;
    top: 8px;
    left: 0; }
  .home .description {
    margin: 0 0 48px;
    font-size: 22px;
    font-weight: 400;
    color: #666; }

.question {
  width: 70%;
  margin-bottom: 10px; }
  .question .header {
    width: 100%;
    text-align: center; }
    .question .header .subtitle {
      font-weight: 400; }
  .question .options {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around; }
    .question .options .item-option {
      display: flex;
      flex-direction: column;
      width: 48%;
      margin-bottom: 16px; }
      .question .options .item-option .image {
        position: relative;
        padding: 16px;
        margin-bottom: 8px;
        text-align: center;
        background-color: #444;
        border-radius: 3px;
        cursor: pointer; }
        .question .options .item-option .image:hover {
          background-color: #536dfe; }
        .question .options .item-option .image img {
          width: 100%;
          max-width: 220px; }
        .question .options .item-option .image .effect {
          position: absolute;
          top: 8px;
          right: 8px; }
          .question .options .item-option .image .effect span {
            display: inline-block;
            padding: 0 2px;
            font-size: 18px;
            color: #f5f5f5; }
      .question .options .item-option .label {
        font-size: 18px; }

.resume {
  width: 26%;
  margin: 32px 0;
  text-align: center;
  border-radius: 3px; }
  .resume .logo-resume {
    max-height: 120px;
    margin-bottom: 16px; }
  .resume .title-resume {
    margin: 0;
    text-align: left; }
  .resume .item-resume {
    padding: 8px 16px;
    margin-bottom: 4px;
    text-align: left;
    color: #fff;
    background-color: #AECFFF;
    border-radius: 3px; }
    .resume .item-resume .item-resume-title {
      margin: 0; }
